var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "borderWrap"
  }, [_c('div', {
    ref: "scrollContainer",
    staticClass: "tblScroll tblFixed",
    on: {
      "scroll": _vm.handleScroll
    }
  }, [_c('table', {
    staticClass: "tbl"
  }, [_c('caption', [_vm._v("분석이력 리스트")]), _c('colgroup', [_c('col', {
    staticStyle: {
      "width": "80px"
    }
  }), _c('col', {
    staticStyle: {
      "width": "12%"
    }
  }), _c('col'), _c('col', {
    staticStyle: {
      "width": "25%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "10%"
    }
  }), _c('col', {
    staticStyle: {
      "width": "16%"
    }
  }), !_vm.requestNo ? _c('col', {
    staticStyle: {
      "width": "15%"
    }
  }) : _vm._e()]), _c('thead', [_c('tr', [_c('th', [_vm._v("No.")]), _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.pictureDt")))]), _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.type")))]), _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.result")))]), _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.crtId")))]), _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.crtDt")))]), !_vm.requestNo ? _c('th', [_vm._v(_vm._s(_vm.$t("page.record.list.delete")))]) : _vm._e()])]), _vm.isGetingItems ? _c('tbody', _vm._l(_vm.preSize, function (n) {
    return _c('tr', {
      key: n
    }, _vm._l(7, function (na) {
      return _c('td', {
        key: na,
        staticStyle: {
          "padding": "0 1rem"
        }
      }, [_c('ContentLoader', {
        attrs: {
          "speed": 1,
          "width": "100",
          "height": 15
        }
      }, [_c('rect', {
        attrs: {
          "x": "15",
          "y": "3",
          "rx": "3",
          "ry": "3",
          "width": 70,
          "height": 10
        }
      })])], 1);
    }), 0);
  }), 0) : !_vm.isGetingItems && _vm.list.length == 0 ? _c('tbody', [_c('tr', [_c('td', {
    attrs: {
      "colspan": !_vm.requestNo ? `7` : `6`
    }
  }, [_vm._v(_vm._s(_vm.$t('page.record.list.msg.noResult')))])])]) : _c('tbody', _vm._l(_vm.list, function (item, i) {
    return _c('tr', {
      key: i
    }, [_c('td', [_vm._v(_vm._s(_vm.total - i))]), _c('td', [_vm._v(_vm._s(item.pictureDtStr))]), _c('td', [_vm._v(_vm._s(_vm.getXrayTypeName(item.xrayType)))]), _c('td', [_c('div', {
      staticClass: "btnW"
    }, [_c('button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple"
      }],
      key: _vm.recordKey,
      staticClass: "btn solid small",
      on: {
        "click": function ($event) {
          return _vm.recordView(`${item.xrayType}`, `${item.no}`, $event);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("page.record.list.button.view")))]), item.xrayType === 'both' ? _c('button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple"
      }],
      staticClass: "btn default small",
      on: {
        "click": function ($event) {
          return _vm.openReport(`${item.no}`, $event);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("page.record.list.button.report")))]) : _vm._e()])]), _c('td', [_vm._v(_vm._s(item.doctorNm))]), _c('td', [_vm._v(_vm._s(item.crtDtStr))]), !_vm.requestNo ? _c('td', [_c('button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple"
      }],
      staticClass: "btn default small",
      on: {
        "click": function ($event) {
          return _vm.deleteRecord(i, `${item.no}`, $event);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("common.button.del")))])]) : _vm._e()]);
  }), 0)])])]), _c('v-dialog', {
    attrs: {
      "persistent": ""
    },
    model: {
      value: _vm.reportVisible,
      callback: function ($$v) {
        _vm.reportVisible = $$v;
      },
      expression: "reportVisible"
    }
  }, [_c('Report', {
    key: _vm.componentKey,
    attrs: {
      "onClick": function () {
        _vm.reportVisible = false;
      },
      "xrayNo": _vm.xrayNo
    },
    on: {
      "closeReport": _vm.closeReport
    }
  })], 1), _c('v-dialog', {
    attrs: {
      "width": "320"
    },
    model: {
      value: _vm.openPopup,
      callback: function ($$v) {
        _vm.openPopup = $$v;
      },
      expression: "openPopup"
    }
  }, [_c('DefaultPopup', {
    attrs: {
      "popupText": _vm.popupText,
      "secondTxt": _vm.popupButton,
      "firstBtn": true,
      "onClick": function () {
        _vm.deleteProgress(_vm.selectedNo);
      }
    },
    on: {
      "closePopup": function ($event) {
        _vm.openPopup = false;
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }