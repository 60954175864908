<template>
    <div>
    <div class="borderWrap">
        <div class="tblScroll tblFixed" ref="scrollContainer" @scroll="handleScroll">
            <table class="tbl">
                <caption>분석이력 리스트</caption>
                <colgroup >
                    <col style="width: 80px;">
                    <col style="width: 12%;">
                    <col>
                    <col style="width: 25%;">
                    <col style="width: 10%;">
                    <col style="width: 16%;">
                    <col v-if="!requestNo" style="width: 15%;">
                </colgroup>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>{{ $t("page.record.list.pictureDt") }}</th>
                        <th>{{ $t("page.record.list.type") }}</th>
                        <th>{{ $t("page.record.list.result") }}</th>
                        <th>{{ $t("page.record.list.crtId") }}</th>
                        <th>{{ $t("page.record.list.crtDt") }}</th>
                        <th v-if="!requestNo">{{ $t("page.record.list.delete") }}</th>
                    </tr>
                </thead>
                <tbody v-if="isGetingItems">
                    <tr v-for="n in preSize" :key="n">
                        <td v-for="na in 7" :key="na" style="padding:0 1rem;">
                            <ContentLoader :speed="1" width="100" :height="15">
                                <rect x="15" y="3" rx="3" ry="3" :width="70" :height="10" />
                            </ContentLoader>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="!isGetingItems && list.length == 0">
                    <tr><td :colspan="!requestNo?`7`:`6`">{{ $t('page.record.list.msg.noResult') }}</td></tr>
                </tbody>
                <tbody v-else>
                    <tr v-for="(item, i) in list" :key="i">
                        <td>{{ total - i }}</td>
                        <td>{{ item.pictureDtStr }}</td>
                        <td>{{ getXrayTypeName(item.xrayType) }}</td>
                        <td>
                            <div class="btnW">
                                <button class="btn solid small" @click="recordView(`${item.xrayType}`, `${item.no}`, $event);" :key="recordKey" v-ripple>{{ $t("page.record.list.button.view") }}</button>
                                <button class="btn default small" v-if="item.xrayType === 'both'" @click="openReport(`${item.no}`, $event)" v-ripple>{{ $t("page.record.list.button.report") }}</button>
                            </div>
                        </td>
                        <td>{{ item.doctorNm }}</td>
                        <td>{{ item.crtDtStr }}</td>
                        <td v-if="!requestNo"><button class="btn default small" @click="deleteRecord(i, `${item.no}`, $event)" v-ripple>{{ $t("common.button.del") }}</button></td>
                    </tr>
                </tbody>
            </table>
            </div>
        </div>
            <v-dialog v-model="reportVisible" persistent>
                <Report
                    :onClick="() => { reportVisible = false }"
                    :xrayNo="xrayNo"
                    @closeReport="closeReport"
                    :key="componentKey"
                />
            </v-dialog>
            <!-- <v-dialog v-model="spineReportVisible" persistent>
                <spineReport
                    :onClick="() => { spineReportVisible = false }"
                    :xrayNo="xrayNo"
                    @closeReport="closeSpineReport"
                    :key="spineReportKey"
                />
                </v-dialog> -->
            <v-dialog v-model="openPopup" width="320">
                <DefaultPopup
                    :popupText="popupText"
                    :secondTxt="popupButton"
                    :firstBtn="true"
                    :onClick="() => { deleteProgress(selectedNo); }"
                    @closePopup="openPopup = false"
                />
            </v-dialog>
        </div>
</template>

<script>
import API_RECORDS from '../../../API/record/list'
import API_DEL_RECORD from '../../../API/record/delete'
import EventBus from '../../../plugins/EventBus'
import { ContentLoader } from 'vue-content-loader'
export default {
    components: {
        Report: () => import('../report/totReport.vue'),
        // spineReport: () => import('../report/spineReport.vue'),
        ContentLoader,
    },
    props: {
        patientNo: {
            default: ''
        },
        isDetail: {
            default: true
        },
        requestNo: {
            default: null
        }
    },
    data() {
        return {
            list: [],
            pageNo: 1,
            pageSize: 20,
            total: 0,
            isGetingItems: false,
            reportVisible: false,
            xrayNo: '',
            componentKey: 0,
            recordKey: 0,
            openPopup: false,
            selectedNo: '',
            patientInfo: {},
            loading: false,
            reachedEnd: false,
            selectedIndex: 0,
            preSize: 1,
            pineDx: process.env.VUE_APP_PINE_DX,
            // spineReportVisible: false,
            // spineReportKey: 0,

        };
    },
    watch: {},
    created() { },
    async mounted() {
        await this.loadRecordList();
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    destroyed() { },
    methods: {
        async loadMoreRecords() {
            if (this.loading || this.reachedEnd) return;
            this.loading = true;

            const nextPage = this.pageNo + 1;
            let res = await API_RECORDS.request(nextPage, this.pageSize, this.patientNo);

            if (res.isSuccess) {
                this.list = [...this.list, ...res.list];
                this.pageNo = nextPage;
                this.loading = false;
                if (res.list.length === 0) {
                    this.reachedEnd = true;
                }
            } else {
                this.loading = false;
                this.showPopup(res.errorMsg, res.status);
            }
        },
        handleScroll() {
            const scrollContainer = this.$refs.scrollContainer;
            const scrollHeight = scrollContainer.scrollHeight;
            const scrollTop = scrollContainer.scrollTop;
            const clientHeight = scrollContainer.clientHeight;
            const bottomOffset = 110;

            if (scrollHeight - scrollTop - clientHeight <= bottomOffset) {
                this.loadMoreRecords();
            }
        },
        async loadRecordList() {
            if (this.isGetingItems) {
                return;
            }
            this.isGetingItems = true;
            let res = await API_RECORDS.request(this.pageNo, this.pageSize, this.patientNo);
            if (res.isSuccess) {
                this.list = res.list;
                this.total = res.total;
                this.preSize = Math.max(1, this.list.length);
                this.preSize = Math.min(13, this.list.length);
                if (res.list.length < this.pageSize) {
                    this.reachedEnd = true;
                }
            }else {
                this.showPopup(res.errorMsg, res.status);
            }
            this.isGetingItems = false;
        },
        getXrayTypeName(xrayType) {
            let type = xrayType.toLowerCase();
            let typeNm = "";
            switch (type) {
                case this.XRAY_TYPE_SPINE:
                    typeNm = this.$t("common.analysis.type.spine")
                    break;
                case this.XRAY_TYPE_HANDS:
                    typeNm = this.$t("common.analysis.type.hands")
                    break;
                case this.XRAY_TYPE_BOTH:
                    typeNm = this.$t("common.analysis.type.spine") + ", " + this.$t("common.analysis.type.hands")
                    break;
            }
            
            return typeNm;
        },
        recordView(type, no, e) {
            e.preventDefault();
            this.patientInfo = localStorage.getItem('patientInfo');
            localStorage.setItem('targetInfo', this.patientInfo);
            EventBus.emit('change_target');
            this.$router.push({
                name: 'patientRecord',
                params: { type: type, no: no}
            }).catch(() => { })
            this.recordKey += 1;
        },
        openReport(xrayNo, event) {
            event.preventDefault();
            this.componentKey += 1
            this.xrayNo = xrayNo;
            this.reportVisible = true;
        },
        closeReport() {
            this.reportVisible = false;
        },
        deleteRecord(index, no, event) {
            event.preventDefault();
            this.openPopup = true;
            this.selectedNo = no;
            this.selectedIndex = index;
        },
        async deleteProgress(no) {
            let res = await API_DEL_RECORD.request(no);
            if (res.isSuccess) {
                this.list.splice(this.selectedIndex, 1);
                this.openPopup = false;
            } else { // 실패
                this.openPopup = false;
                this.showPopup(res.errorMsg, res.status);
            }
        },
    },
    computed: {
        popupText(){
            return this.$t('page.record.list.msg.delRecord')
        },
        popupButton(){
            return this.$t('common.button.ok')
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.tblScroll{
    max-height: 430px;
    .tbl{
        border-bottom: 0;
    }
    
}

</style>